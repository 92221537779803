import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { green } from 'src/styles/colors'

export const Section = styled.section`
  background: ${green[500]};
  min-height: 631px;
  border-radius: 0px 92px 0px 0px;

  @media ${device.tablet} {
    width: 768px;
    min-height: 451px;
  }

  @media ${device.desktopLG} {
    width: auto;
    min-height: 451px;
  }

  @media ${device.desktopXL} {
    min-height: 603px;
  }
`

export const IconContainer = styled.div`
  background-color: #FF7A00;
  width: 72px;
  height: 72px;
  border-radius: 130px;
`
