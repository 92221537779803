import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.div`
  .bread-crumb span{
    color: ${grayscale[400]} !important;
    font-weight: 700 !important;
    font-size: 14px;
    a {
      color: ${grayscale[500]} !important;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .btn {
    height: 48px;
    width: 306px;
  }

  .react-multi-carousel-track {
    padding: 0;

    @media ${device.desktopLG} {
      padding-top: 48px;
      padding-bottom: 39px;
    }
  }
`
export const Cards = styled.div`
  border: 1px solid #DEDFE4;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 10px;
  height: 200px;
  min-width: 200px;
`
