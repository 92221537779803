import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      heroMenGlobalAccount: imageSharp(fluid: { originalName: { regex: "/hero-men-global-account/" }}) {
        fluid(maxWidth: 496, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      heroComoAbrirConta: imageSharp(fluid: { originalName: { regex: "/hero-como-abrir-conta/" }}) {
        fluid(maxWidth: 496, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
