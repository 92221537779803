import React, { useState } from 'react'
import { Modal } from 'src/components/Modal'
import useUtms from 'src/hooks/useUtms'
import useDomReady from 'src/hooks/window/useDomReady'

import Layout from 'src/layouts/BaseLayout'

import CompartilharForm from './components/form/_index'
import AbraSuaConta from './sections/abra-sua-conta/_index'
import AproveiteAsVantagens from './sections/aproveite-as-vantagens/_index'
import ConquisteNovosMercados from './sections/conquiste-novos-mercados/_index'
import { FaqSection } from './sections/faq/_index'
import { Hero } from './sections/hero/_index'

import pageContext from './pageContext.json'

const GlobalAccountBusiness = () => {
  const domReady = useDomReady()
  const [ isOpen, setIsOpen ] = useState(false)
  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}`, campanha: '' })

  const compartilheModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <CompartilharForm closeModal={() => setIsOpen(false)} />
    </Modal>
  )

  const openAccountPJFormModal = () => {
    window.location.href = utmLink
  }

  return (
    <Layout handleHeaderOpenAccount={() => openAccountPJFormModal()} pageContext={pageContext}>
      {compartilheModal}
      <Hero setIsOpen={setIsOpen} />
      <ConquisteNovosMercados />
      <AbraSuaConta />
      <AproveiteAsVantagens />
      <FaqSection />
    </Layout>
  )
}

export default GlobalAccountBusiness
