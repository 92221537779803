import Img from 'gatsby-image'
import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import dataJson from './../../assets/data/global-account-business.json'

import BreadCrumb from 'src/components/Breadcrumb'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import OrangeDS from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'
import usePageQuery from './../../pageQuery'

import { Cards, Section } from './style'

type HeroProps = {
  setIsOpen: (isOpen: boolean) => void;
};

type CardType = {
  icon: string;
  description: string;
}

export const Hero = ({ setIsOpen }: HeroProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const data = usePageQuery()
  const width = useWidth(300)

  const cards: CardType[] = dataJson

  return (
    <Section>
      <div className='container'>
        <div className='row pt-4 pt-xl-5 pl-md-3 pb-lg-0 align-items-center'>
          <div className='col-12 col-lg-6 col-xl-7 px-0'>
            <div className='d-flex mb-4'>
              <BreadCrumb
                sectionName='Global Account Business - Sua conta internacional PJ'
                type={<OrangeDS color='#FF7A00' size='MD' icon='home' className='mr-1' />}
                product='Empresas'
                text='Conta Digital PJ'
                link='/empresas/conta-digital/pessoa-juridica/'
              />
            </div>

            <h1 className='fs-24 lh-30 fs-md-32 lh-md-40 fw-600 text-grayscale--500 font-sora mb-3'>
              <span className='d-block text-orange--extra'>Global Account Business </span>
              Sua conta internacional PJ
            </h1>

            <p className='fs-18 lh-22 fw-400 text-grayscale--500 mb-0'>
              A segurança e a agilidade de uma plataforma de câmbio. A liberdade e a conveniência de uma conta internacional
              nos Estados Unidos. Tudo o que seu negócio precisa para ir mais longe.
            </p>

            {(width < WIDTH_MD) ? (
              <button
                className='btn btn-orange bg-orange--extra text-white text-none rounded-2 mw-100 my-4'
                type='button'
                title='Abrir Global Account'
                onClick={() => {
                  setIsOpen(true)
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    section_name: 'Global Account Business Sua conta internacional PJ',
                    element_action: 'click button',
                    element_name: 'Abrir Global Account',
                  })
                }}
              >
                Abrir Global Account
                <OrangeDS color='white' size='MD' icon='chevron-right' className='ml-5' />
              </button>
            ) : (
              <a
                href='https://conta-digital-pj.inter.co/login?&gaid=GA1.1.913686693.1694522915'
                target='_blank'
                rel='noreferrer'
                className='text-center my-4 mb-lg-0 btn btn-orange--extra fs-14 fw-600 rounded-2 justify-content-between px-0 text-white text-none'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    section_name: 'Global Account Business Sua conta internacional PJ',
                    element_action: 'click button',
                    element_name: 'Abrir Global Account',
                    redirect_url: 'https://conta-digital-pj.inter.co/login?&gaid=GA1.1.913686693.1694522915',
                  })
                }}
              >
                Abrir Global Account
                <OrangeDS color='white' size='MD' icon='chevron-right' className='ml-5' />
              </a>
            )}
          </div>

          <div className='d-none d-lg-block d-xl-none col-lg-5 offset-lg-1'>
            <Img
              fluid={data.heroMenGlobalAccount.fluid}
              alt='Empreendedor sorrindo enquanto acessa sua conta internacional PJ Inter'
            />
          </div>

          <div className='d-none d-xl-block col-xl-4 offset-xl-1'>
            <Img
              fluid={data.heroMenGlobalAccount.fluid}
              alt='Empreendedor sorrindo enquanto acessa sua conta internacional PJ Inter'
            />
          </div>
        </div>

        <div className='col-12 col-lg-12 col-xl-10 px-0 pb-4 pb-lg-0 pr-lg-5'>
          <DefaultCarousel
            sm={{
              items: 1,
              partialVisibilityGutter: 90,
            }}
            md={{
              items: 3,
              partialVisibilityGutter: 25,
            }}
            lg={{ items: 4 }}
            xl={{ items: 4 }}
            notShowArrows
            notShowDots
          >
            {cards.map((item: CardType, index: number) => (
              <Cards
                className='rounded-3 d-flex flex-column'
                key={index}
              >
                <OrangeDS color='#FF7A00' size='MD' icon={item.icon} className='ml-4 my-4' />
                <p dangerouslySetInnerHTML={{ __html: item.description }} className='fs-16 lh-19 text-grayscale--400 fw-400 ml-4' />
              </Cards>
              ),
            )}
          </DefaultCarousel>
        </div>
      </div>
    </Section>
  )
}
