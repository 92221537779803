import React from 'react'
import OrangeDS from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import ConquisteNovosMercadosJSON from '../../assets/data/conquiste-novos-mercados.json'

import { Section, IconContainer } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type CardType = {
  icon: string;
  title: string;
  description: string;
}

const ConquisteNovosMercados = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5 d-flex align-items-end align-items-lg-center'>
      <div className='container'>
        <div className='row justify-content-md-center'>

          <div className='col-12 mb-4'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fw-600 text-white text-center font-sora'>
              Conquiste novos mercados com uma <span className='d-lg-block'>Global Account Business</span>
            </h2>
          </div>

          {ConquisteNovosMercadosJSON.map((item: CardType, index: number) => (
            <div className='col-12 col-md-8 col-lg-4 d-flex flex-column align-items-center mb-3' key={index}>
              <IconContainer>
                <OrangeDS color='#FFFFFF' size='MD' icon={item.icon} className='ml-4 my-4' />
              </IconContainer>
              <h3 className='fs-20 lh-25 fw-400 text-white text-center fw-600 mt-4 py-2' dangerouslySetInnerHTML={{ __html: item.title }} />
              <p className='fs-16 lh-19 fw-400 text-white text-center mt-0'>
                {item.description}
              </p>
            </div>
          ))}

          <a
            href='https://conta-digital-pj.inter.co/login?&gaid=GA1.1.913686693.1694522915'
            target='_blank'
            rel='noreferrer'
            className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mt-md-5 mt-lg-4'
            onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_02',
                section_name: 'Conquiste novos mercados com uma Global Account Inter',
                element_action: 'click button',
                element_name: 'Abrir Global Account',
                redirect_url: 'https://conta-digital-pj.inter.co/login?&gaid=GA1.1.913686693.1694522915',
              })
            }}
          >
            Abrir Global Account
          </a>

        </div>
      </div>
    </Section>

  )
}

export default ConquisteNovosMercados
