import React from 'react'

// Hooks
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

// Components
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

// Data
import aproveiteAsVantagensJSON from '../../assets/data/aproveite-as-vantagens.json'

// styles
import { Section } from './style'

type secureYourCardType = {
  icon: string;
  text: string;
}

const AproveiteAsVantagens = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='bg-white py-5 d-flex align-items-end' role='img' aria-label='Empreendedor acessando sua Global Account através do Internet Banking'>
      <div className='container'>
        <div className='row d-flex align-items-center flex-column justify-content-md-center align-items-xl-start justify-content-xl-start'>

          <div className='col-12 col-md-8 col-lg-9 col-xl-6'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fw-600 text-grayscale--500 mb-4 mb-md-4 pb-md-2'>
              Saiba mais sobre a <span className='d-md-block'>Global Account Business</span>
            </h2>

            {
              aproveiteAsVantagensJSON.map((item: secureYourCardType) => (
                <div key={item.text} className='d-flex mb-4'>
                  <div className='mr-3'>
                    <OrangeIcon size='MD' color='#00796B' icon={item.icon} />
                  </div>
                  <div>
                    <p className='fs-18 lh-22 text-grayscale--400 mb-0' dangerouslySetInnerHTML={{ __html: item.text }} />
                  </div>
                </div>
              ))
            }

            <div className='d-flex justify-content-md-center'>
              <a
                href='https://conta-digital-pj.inter.co/login?&gaid=GA1.1.913686693.1694522915'
                target='_blank'
                rel='noreferrer'
                className='btn btn--lg rounded-2 fs-14 text-white text-none mt-4'
                onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_04',
                  section_name: 'Seu cartão de débito Inter Mastercard pronto pro mundo como você',
                  element_action: 'click button',
                  element_name: 'Abrir Global Account',
                  redirect_url: 'https://conta-digital-pj.inter.co/login?&gaid=GA1.1.913686693.1694522915',
                })
                }}
              >
                Abrir Global Account
              </a>
            </div>

          </div>
        </div>
      </div>
    </Section>
  )
}

export default AproveiteAsVantagens
