import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { green, white } from 'src/styles/colors'
import { rounded } from 'src/styles/rounded'

export const ImgSize = styled.div`
  .gatsby-image-wrapper, img {
    max-width: 100%!important;
    max-height: fit-content!important;

    @media ${device.tablet} {
      max-width: 402px!important;
    }

    @media ${device.desktopLG} {
      max-width: 573px !important;
      float: right;
    }
  }
`

export const Section = styled.section`
  .btn {
    background-color: ${green[600]};
  }
`

export const Number = styled.span`
  width: 32px;
  height: 32px;
  background: ${green[600]};
  color: ${white};
  border-radius: ${rounded['6']};
  display: block;
  text-align: center;
  padding-top: 3px;
  font-size: 16px;
`
