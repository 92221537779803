import React, { useState } from 'react'

import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import OrangeDS from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { Modal } from 'src/components/Modal'
import WhatsappBottomSheet from 'src/components/WhatsappBottomSheet'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useDomReady from 'src/hooks/window/useDomReady'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import { Container, CloseButton } from './style'

type CompartilharFormProps = {
  closeModal: () => void;
}

function CompartilharForm ({ closeModal }: CompartilharFormProps) {
  const domReady = useDomReady()
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ bottomSheet, setBottomSheet ] = useState(false)

  const whatsappMessage = 'Acesse o link abaixo através de um computador para abrir sua Global Account PJ pelo Internet Banking. https://contadigital.inter.co/'

  const dataLayer: IDataLayerParams = {
    section: 'm_dobra_01',
    section_name: 'Abra sua Global Account PJ',
    element_action: 'click button',
    element_previous: 'Compartilhar link',
    element_name: 'Compartilhar via whatsapp',
  }

  const closeButton = closeModal && (
    <CloseButton className='border-0 bg-transparent pt-3' onClick={closeModal}>
      <Close width='24' height='24' color='orange--extra' />
    </CloseButton>
  )

  const BottomSheetMobile = domReady && (
    <Modal isModalOpen={bottomSheet} setIsModalOpen={setBottomSheet} locationToRender={document.body}>
      <WhatsappBottomSheet whatsappMessage={whatsappMessage} title='Abra sua Global Account PJ' subTitle='Acesse o link abaixo através de um computador para abrir sua Global Account PJ pelo Internet Banking.' dataLayer={dataLayer} />
    </Modal>
  )

  return (
    <>
      <Container
        isModal={closeModal}
        className='d-flex align-items-md-center align-items-lg-start'
      >
        {closeButton}
        {BottomSheetMobile}
        <div className='form--default'>
          <div className='row'>
            <div className='col-12 pt-3 pb-4 mb-2'>
              <h3 className='fs-24 lh-30 text-grayscale--500 my-3'>
                Abra sua Global<span className='d-block'> Account PJ</span>
              </h3>
              <p className='fs-18 lh-22 text-grayscale--500 mb-4'>
                O acesso a Global Account PJ deve ser feito por meio do Internet Banking PJ em um computador com o link:
              </p>
              <p className='fs-18 lh-22 fw-700 text-grayscale--500 mb-4'>
                contadigital.inter.co
              </p>
              <p className='fs-18 lh-22 text-grayscale--500 mb-4'>
                Você pode compartilhar o link de acesso através do app de sua preferência pelo botão abaixo.
              </p>
            </div>

            <div className='col-12 mb-4 mb-md-0'>
              <button
                title='Compartilhar link'
                className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none mb-4 mb-xl-0'
                onClick={() => {
                  setBottomSheet(!bottomSheet)
                  sendDatalayerEvent({
                    section: 'm_dobra_01',
                    section_name: 'Abra sua Global Account PJ',
                    element_action: 'click button',
                    element_name: 'Compartilhar link',
                    element_previous: 'Abrir Global Account',
                  })
                }}
              >
                <div className='btn btn-orange--extra fs-14 fw-600 rounded-2 d-flex justify-content-between px-0 text-white text-none'>
                  Compartilhar link
                  <OrangeDS color='white' size='MD' icon='share' className='ml-5' />
                </div>
              </button>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default CompartilharForm
