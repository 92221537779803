import React from 'react'
import Img from 'gatsby-image'

// Hooks
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import usePageQuery from './../../pageQuery'

// styles
import { ImgSize, Number, Section } from './style'

const AbraSuaConta = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='bg-gray py-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row align-items-md-center justify-content-md-center'>
          <div className='col-12 col-md-10 col-lg-8 col-xl-6 d-md-flex flex-column justify-content-md-center'>
            <ImgSize className='d-md-flex justify-content-md-center my-4 d-xl-none'>
              <Img fluid={data.heroComoAbrirConta.fluid} alt='Cliente Inter Empresas acessando sua global account Business através do Internet Banking.' />
            </ImgSize>

            <div className='d-lg-flex flex-lg-column justify-content-ld-center'>
              <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fw-600 text-grayscale--500 mb-5 py-2'>
              Abra sua Global Account Business 
              </h2>
              <div className='d-flex mb-2 mb-md-3'>
                <div className='mr-3 mr-md-4'><Number>1</Number></div>
                <div>
                  <h3 className='fs-20 lh-25 fw-600 text-grayscale--500 mb-2 font-sora'>Cadastre-se</h3>
                  <p className='fs-18 lh-22 text-grayscale--400'>Abra sua conta Inter Empresas. Serão necessários apenas os dados da sua empresa, representante legal e contrato social.</p>
                </div>
              </div>

              <div className='d-flex mb-2 mb-md-3'>
                <div className='mr-3 mr-md-4'><Number>2</Number></div>
                <div>
                  <h3 className='fs-20 lh-25 fw-600 text-grayscale--500 mb-2 font-sora'>Confirmação</h3>
                  <p className='fs-18 lh-22 text-grayscale--400'>Após abrir sua conta Inter Empresas, você terá a opção de solicitar a abertura da Global Account Business. Verifique se a sua empresa atende aos critérios de abertura da conta internacional.</p>
                </div>
              </div>

              <div className='d-flex mb-md-3'>
                <div className='mr-3 mr-md-4'><Number>3</Number></div>
                <div>
                  <h3 className='fs-20 lh-25 fw-600 text-grayscale--500 mb-2 font-sora'>Tudo pronto</h3>
                  <p className='fs-18 lh-22 text-grayscale--400 mb-0'>Agora é só aproveitar sua conta internacional para o seu negócio, com account number, routing number, swift code e muito mais.</p>
                </div>
              </div>

              <div className='d-flex justify-content-md-center'>
                <a
                  href='https://conta-digital-pj.inter.co/login?&gaid=GA1.1.913686693.1694522915'
                  target='_blank'
                  rel='noreferrer'
                  className='btn btn--lg rounded-2 fs-14 text-white text-none mt-5'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_08',
                      section_name: 'Como abrir sua Global Account?',
                      element_action: 'click button',
                      element_name: 'Abrir Global Account',
                      redirect_url: 'https://conta-digital-pj.inter.co/login?&gaid=GA1.1.913686693.1694522915',
                    })
                  }}
                >
                  Abrir Global Account
                </a>
              </div>
            </div>

          </div>

          <ImgSize className='col-xl-6 my-4 d-none d-xl-block order-xl-first'>
            <Img fluid={data.heroComoAbrirConta.fluid} alt='Cliente Inter Empresas acessando sua global account Business através do Internet Banking.' />
          </ImgSize>

        </div>
      </div>
    </Section>
  )
}

export default AbraSuaConta
