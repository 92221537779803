import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { green } from 'src/styles/colors'

import HeroSM from '../../assets/images/hero-aproveite-as-vantagens.png'
import HeroMD from '../../assets/images/hero-aproveite-as-vantagens-md.png'
import HeroLG from '../../assets/images/hero-aproveite-as-vantagens-lg.png'
import HeroXL from '../../assets/images/hero-aproveite-as-vantagens-xl.png'

export const Section = styled.section`
  background: url(${HeroSM}) no-repeat transparent;
  background-position-y: 24px;
  height: 904px;

  @media ${device.tablet} {
    background: url(${HeroMD}) no-repeat transparent;
    height: 1040px;
  }

  @media ${device.desktopLG} {
    background: url(${HeroLG}) no-repeat transparent;
    height: 1150px;
  }

  @media ${device.desktopXL} {
    background: url(${HeroXL}) no-repeat transparent;
    background-position: right;
    height: 603px;
  }

  .btn {
    background-color: ${green[600]};
  }
`

export const ImgSize = styled.div`
  .gatsby-image-wrapper {
    @media ${device.desktopXL} {
      max-width: 456px!important;
      max-height: none!important;
      float: left;
    }
  }
`
