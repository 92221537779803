import React from 'react'

import faqData from '../../pageContext.json'

// Components
import Faq from 'src/components/Faq'

// styles
import { Section } from './style'

export const FaqSection = () => {
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-32 lh-40 fs-xl-40 lh-xl-50 mb-5 text-grayscale--500 text-center fw-600 font-sora'>Perguntas frequentes</h2>
            <Faq
              columns={{ sm: 1, md: 1, lg: 1, xl: 1 }}
              searchBg='#ffffff'
              answerData={faqData.structuredData.faq}
            />
            <p className='fs-14 lh-17 text-grayscale--400 pt-5'>
              INTER & CO PAYMENTS INC., doing business as Inter Global (“INTER”), entidade regulada e autorizada a funcionar nos termos das regulamentações dos Estados Unidos da América (EUA) é responsável por ofertar a Global Account e por prestar os serviços a ela relacionados. A Global Account é um serviço financeiro regulado nos EUA e, portanto, obedece às leis e regulações deste país. A INTER & CO PAYMENTS INC., não é um banco. O BANCO INTER S.A., entidade brasileira devidamente autorizada a funcionar pelo Banco Central do Brasil, figura como intermediário. O cartão de débito da sua Global Account é um cartão de débito emitido pelo American State Bank, membro do FDIC, nos termos de uma licença da Mastercard®. Veja o contrato de uso de cartão para mais detalhes.
            </p>
            <p className='fs-14 lh-17 text-grayscale--400'>
              Os depósitos da Global Account são mantidos junto ao Community Federal Savings Bank (“Banco Parceiro”), membro do Federal Deposit Insurance Corporation (“FDIC”).
            </p>
            <p className='fs-14 lh-17 text-grayscale--400'>
              Se tiver alguma dúvida referente ao extrato, basta ligar para um dos números adiante: 3003 4070 capitais e regiões metropolitanas, 0800 940 0007 demais localidades e 0800 979 7099 para acessibilidade. Para informações adicionais sobre o Inter, acesse https://ajuda.inter.co/. Nosso setor de atendimento está disponível a qualquer hora.
            </p>
          </div>
        </div>
      </div>
    </Section>
  )
}
